// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useSettingApi() {
  // Use toast
	const toast = useToast()
	// Table Handlers
	const settings = ref(null)
	const maintenance = ref(false)
	const isLoading = ref(true)
	const reFormArray = ref(null)
	const fetchSettings = (ctx, callback)=>{
		store.dispatch('settingStore/fetchSettings').then(response=>{
			const {data} = response
			settings.value = data;
		}).catch(error=>{
			console.log(error);
		})
	}

	const fetchMaintenance = (ctx, callback)=>{
		store.dispatch('settingStore/fetchMaintenance').then(response=>{
			const {data} = response
			maintenance.value = data.data;
		}).catch(error=>{
			console.log(error);
		})
	}

	return {
		toast,
		settings,
		isLoading,
		fetchSettings,
		reFormArray,
		fetchMaintenance,
		maintenance
	}
}
