<<template>
  <b-sidebar id="edit-setting-sidebar" :visible="isEditSettingSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right
    @change="(val) => $emit('update:is-edit-setting-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Setting
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" v-if="editSetting">
        <b-form class="p-2" @submit.prevent="handleSubmit(updateSettingSubmit)">

            <validation-provider #default="{ errors }" name="Title" rules="required">
              <b-form-group label="Title" label-for="title">
                <b-form-input :state="errors.length > 0 ? false:null" placeholder="Title" v-model="editSetting.title" />
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="Type" rules="required">
              <b-form-group label="Type" label-for="type">
                <v-select :state="errors.length > 0 ? false:null" class="text-capitalize" v-model="editSetting.type"
                  label="type" :options="['general','snapchat','google','facebook','tiktok']" />
              </b-form-group>
            </validation-provider>

          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Update
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
  </template>>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import useSettingApi from '@/composables/useSettingApi'
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditSettingSidebarActive',
    event: 'update:is-edit-setting-sidebar-active',
  },
  props: {
    isEditSettingSidebarActive: {
      type: Boolean,
      required: true,
    },
    editSetting:[],

  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {

    const {
      toast,
    } = useSettingApi()


    const updateSettingSubmit = () => {
      store.dispatch('settingStore/updateSetting', {
        id:props.editSetting.id,
        title: props.editSetting.title,
        type:props.editSetting.type,
      }).then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully updated!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-edit-setting-sidebar-active', false)
      }).catch((error)=>{
          console.log(error)
      })
    }

   const {
      refFormObserver,
      getValidationState,
    } = formValidation()
    return {
      updateSettingSubmit,
      refFormObserver,
      getValidationState,
    }
  },

}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>