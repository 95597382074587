<template>

    <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" v-if="settings"
        nav-class="nav-left">

        <b-tab v-for="(settingList,type) in settings.data.group" :key="type" :active="type=='snapchat'">
            <template #title>
                <feather-icon icon="ChevronsRightIcon" size="18" class="mr-50" />
                <span class="font-weight-bold text-capitalize">{{type}}</span>
            </template>
            <b-card :title="type+' Settings'">
                <b-form @submit.prevent="updateForm(type)">
                    <b-row>

                        <b-col md="12"  class="mb-1"  v-for="setting in settingList" :key="setting.key">
                            <b-form-group label-for="website" :label="setting.title">
                                <b-form-textarea id="bio-area" rows="2" @input="inputHandler(type,setting.title, $event)" v-model="setting.value" :placeholder="setting.title" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit" v-if="!loading">
                                Update
                            </b-button>

                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>

         <b-tab>
            <template #title>
                <feather-icon icon="Edit3Icon" size="18" class="mr-50" />
                <span class="font-weight-bold text-capitalize">Edit Settings</span>
            </template>
            <b-card title="Edit Settings" class="mb-0">
                <setting-list-edit :is-edit-setting-sidebar-active.sync="isEditSettingSidebarActive" :editSetting="editSetting" @refetch-data="fetchSettings"></setting-list-edit>

                <b-form>
                    <b-row>
                        <b-col cols="12">

                            <b-table ref="refSettingListTable" class="position-relative" :items="settings.data.nongroup" responsive :fields="['id','title','type','actions']"
                                :table-class="'build-list-table table dataTable no-footer dtr-column'" primary-key="id"  show-empty
                                empty-text="No matching records found">
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                                <!-- Column: id -->
                                <template #cell(id)="data">
                                    <span class="align-text-top text-capitalize">{{ data.item.id}}</span>
                                </template>

                                <!-- Column: title -->
                                <template #cell(name)="data">
                                    <span class="align-text-top text-capitalize">{{ data.item.title}}</span>
                                </template>

                                <!-- Column: Type -->
                                <template #cell(theme)="data">
                                    <span class="align-text-top text-capitalize">{{ data.item.type}}</span>
                                </template>
                                <!--Column: Actions -->
                                <template #cell(actions)="data">

                                    <b-link class="mr-1" v-b-tooltip.hover.top="'Edit Setting'" @click="fncEditSettingSidebar(data.item.id)">
                                        <feather-icon icon="EditIcon" size="18" />
                                    </b-link>

                                    <b-link class="mr-1" v-b-tooltip.hover.top="'Delete Setting'"  v-on:click="confirmDelete(data.item.id)">
                                        <feather-icon icon="TrashIcon" size="18" />
                                    </b-link>
                                </template>
                            </b-table>

                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-tab>

        <b-tab>
            <template #title>
                <feather-icon icon="DatabaseIcon" size="18" class="mr-50" />
                <span class="font-weight-bold text-capitalize">Create Setting</span>
            </template>
            <b-card title="Create Setting">
                <validation-observer ref="settingForm"  tag="form"  @submit.prevent="validationFormControl().then(createSettingSubmit)">
                    <b-row>
                        <b-col sm="6">
                            <validation-provider #default="{ errors }" name="Title" rules="required">
                                <b-form-group label="Title" label-for="title">
                                    <b-form-input :state="errors.length > 0 ? false:null"  placeholder="Title"  v-model="settingData.title" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col sm="6">
                            <validation-provider #default="{ errors }" name="Type" rules="required">
                                <b-form-group label="Type" label-for="type">
                                    <v-select  :state="errors.length > 0 ? false:null"  class="text-capitalize" v-model="settingData.type"  label="type" :options="['general','snapchat','google','facebook','tiktok']" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" >
                            <b-form-group label-for="Value " label="Value">
                                <b-form-textarea id="bio-area" v-model="settingData.value" rows="2"  placeholder="Type..." />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12"><hr/></b-col>
                        <b-col cols="12">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit" v-if="!loading">
                                Store
                            </b-button>

                        </b-col>
                    </b-row>
                </validation-observer>
            </b-card>
        </b-tab>

        <b-tab>
            <template #title>
                <feather-icon icon="ApertureIcon" size="18" class="mr-50" />
                <span class="font-weight-bold text-capitalize">Maintenance Mode</span>
            </template>
            <b-card title="Maintenance Mode">
                <div>
                    <b-form-checkbox class="custom-control-success" v-model="maintenance" @input="maintenanceMode($event)"  switch/>
                </div>
            </b-card>
        </b-tab>

    </b-tabs>
</template>
<script>
import {
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormInvalidFeedback,
    BTable,
    VBTooltip,
    BLink,
    BFormCheckbox,
    BCardText
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useSettingApi from '@/composables/useSettingApi'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SettingListEdit from '@/components/SettingListEdit.vue'
import { ref } from '@vue/composition-api'

export default {
    components: {
        BTabs,
        BTab,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
        vSelect,
        ValidationObserver,
        ValidationProvider,
        BFormInvalidFeedback,
        BTable,
        VBTooltip,
        BLink,
        SettingListEdit,
        BFormCheckbox,
        BCardText
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data(){
        return {
            settingForm:[],
            settingData:{
                title:'',
                type:'snapchat',
                value:'',
            },
            loading: false,
            required
        }
    },
    methods:{
        createSettingSubmit(e){
            const formData = new FormData();
            formData.append('title',this.settingData.title)
            formData.append('type',this.settingData.type)
            formData.append('value',this.settingData.value)
            this.$store.dispatch('settingStore/addSetting', formData)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully added!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.fetchSettings()

                }).catch((errors) => {
                    console.log(errors.response)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error! Please check form',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text:errors.response?errors.response.data.message:''
                        },
                    })
                })
        },
        validationFormControl(){
            return new Promise((resolve, reject) => {
                this.$refs.settingForm.validate().then(success => {
                if (success) {
                    resolve(true)
                } else {
                    reject()
                }
                })
            })
        },
        updateForm(formType){
            const updateForm = {
                settings: this.settingForm.filter((form)=> form.type.includes(formType))
            }
            const formData = new FormData();
            this.loading = true
            formData.append('settings',updateForm)
            this.$store.dispatch('settingStore/updateAltSettings', updateForm)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Successfully updated!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        },
                    })
                    this.fetchSettings()
                    this.loading = false
                }).catch((errors) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text:'Oops! Something went wrong!',
                        },
                    })
                    this.loading = false
                })
        },
        confirmDelete(id){
            this.$swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to delete the setting?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete!',
            customClass: {
                confirmButton: 'btn btn-primary mr-1',
                cancelButton: 'btn btn-outline-primary ms-1'
            },
            buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('settingStore/deleteSetting', {id:id}).then((response) =>{
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully deleted!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        this.fetchSettings()
                    })
                }
            })
        },
        fncEditSettingSidebar (id){
            this.isEditSettingSidebarActive = true;
            this.$store.dispatch('settingStore/fetchSetting', {id:id}).then((response) =>{
                const {data} = response.data
                this.editSetting = data
            })
        },
        inputHandler(type,title, value) {
            let searchIndex = false
            this.settingForm.find(typeSetting => {
                if (typeSetting.type === type && typeSetting.title === title) {
                    typeSetting.value = value;
                    searchIndex = true
                }
            })
            if(!searchIndex){
                this.settingForm.push({type:type,title:title,value:value})
                searchIndex = false
            }
        },
        reFormArray(){
            this.settingForm = this.settings.data.nongroup
        },
        maintenanceMode(event){
            this.$store.dispatch('settingStore/fetchMaintenanceMode', {status:event}).then((response) =>{
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: `Maintenance mode: ${response.data.data?'On':'Off'}`,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    })
        }
    },
    setup() {
        const isEditSettingSidebarActive = ref(false)
        const editSetting = ref(null)
        const {
            toast,
            settings,
            isLoading,
            fetchSettings,
            fetchMaintenance,
		    maintenance
        } = useSettingApi()
        return{
            toast,
            isEditSettingSidebarActive,
            editSetting,
            settings,
            isLoading,
            fetchSettings,
            fetchMaintenance,
		    maintenance
        }
    },
    mounted() {
        this.fetchSettings()
        this.fetchMaintenance()
    },
    beforeUpdate(){
        this.reFormArray()
    }

}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .card-title{
        text-transform: capitalize;
    }
</style>
